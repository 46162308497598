import { useQuery } from "~hooks/useQuery";
import useLayoutContext from "./useLayoutContext";
import {
  ReleasesListUseParamasDocument,
  ReleaseType,
} from "~graphql/typed-document-nodes";
import useFilterContext from "./useFiltersContext";
import { PRIMARY_NAVIGATION_KEYS } from "../navigation/primary.config";
import { OPTION_ALL } from "~features/reports/constants";

export const useReleaseOptions = (
  eventId?: string,
  options?: {
    representAllAsNull?: boolean;
    releaseType?: ReleaseType;
    includeAllRelease?: boolean;
  }
) => {
  const { representAllAsNull = false, releaseType, includeAllRelease = true } =
    options ?? {};
  const { data, isLoading } = useQuery(
    eventId && ReleasesListUseParamasDocument,
    {
      event: eventId,
      type: releaseType,
    }
  );

  const releases = data?.releases || [];

  const releaseGroupedOptions = [
    ...(includeAllRelease
      ? [
          {
            label: "All releases",
            value: representAllAsNull ? null : OPTION_ALL,
          },
        ]
      : []),
    {
      label: "",
      options: releases.map((release) => ({
        label: release.name,
        value: release.id,
      })),
    },
  ];

  const releaseOptions = [
    ...(includeAllRelease
      ? [
          {
            label: "All releases",
            value: representAllAsNull ? null : OPTION_ALL,
          },
        ]
      : []),
    ...releases.map((release) => ({
      label: release?.name,
      value: release?.id,
    })),
  ];

  return {
    releaseOptions,
    isLoading,
    releaseGroupedOptions,
  };
};

export const useReleaseFilterState = () => {
  const context = useLayoutContext();
  const eventId =
    context.type === PRIMARY_NAVIGATION_KEYS.EVENTS &&
    context.eventInformation?.id;
  const { getFilterValue } = useFilterContext();

  const {
    releaseGroupedOptions,
    isLoading,
    releaseOptions,
  } = useReleaseOptions(eventId);

  const releaseIdState = getFilterValue("releaseId");

  const currentReleaseName = (() => {
    if (releaseIdState) {
      if (releaseIdState === null) {
        return "All releases";
      } else {
        return (
          releaseOptions.find(
            (releaseOption) => releaseOption.value === releaseIdState
          )?.label ?? ""
        );
      }
    }
    return "";
  })();

  return {
    releaseGroupedOptions,
    isLoading,
    currentReleaseName,
    releaseIdState,
    releaseOptions,
  };
};
